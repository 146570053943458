import { PricingType } from 'pages/Orders/Orders/types';
import { CASH, ORDER_IN_KIND } from 'utils/constants';
import { ExchangeCalendar, Exchange, BaseEntry, Actions } from 'utils/types';
import {
  AllowedDeliveries,
  Chain,
  Currency,
  Instrument,
  StandardSettlement,
} from 'utils/types/product';

export type OrderType = 'CREATION' | 'REDEMPTION' | 'SEED';

export type OrderAllowedDeliveries = typeof ORDER_IN_KIND | typeof CASH;

export type SettlementType = 'FOP' | 'DVP';

export enum OrderStatus {
  'PENDING' = 'PENDING',
  'CONFIRMED' = 'CONFIRMED',
  'READY' = 'READY',
  'SETTLED' = 'SETTLED',
  'CANCELED' = 'CANCELED',
}

export type OrderDetailsProductType = Pick<
  Instrument,
  '_id' | 'ticker' | 'name' | 'seriesName' | 'seriesLetter' | 'isin'
> & {
  primaryExchange: Pick<Exchange, 'name' | 'timezone' | 'marketClose' | 'marketOpen'>;
  constituentAmountsPerCreationUnit: {
    ticker: string;
    amount: number;
  }[];
};

interface Price {
  baseTicker: string;
  price: number;
  ticker: string;
}

export interface OrderProductInfo {
  _id: string;
  constituentAmountsPerCreationUnit: ConstituentAmount[];
  cutoffs: Instrument['cutoffs'];
  fundIcon?: string;
  isShort?: boolean;
  isin: string;
  isMarketOpen?: boolean;
  leveragedConstituentsTicker?: string[];
  name: string;
  prices: Price[];
  primaryExchange: PrimaryExchange;
  seriesLetter: string;
  seriesName: string;
  ticker: string;
  timezone: string;
  unitSize: number;
  allowedDeliveries?: AllowedDeliveries[];
  standardSettlement?: StandardSettlement;
  // Token Orders
  sourceChain?: Chain;
  destinationChain?: Chain;
  contractAddress?: string;
  currency?: string;
}

interface PrimaryExchange extends Omit<Exchange, 'calendars'> {
  calendar: ExchangeCalendar;
}

interface ConstituentAmount {
  amount: number;
  ticker: string;
}

export interface WalletInfo {
  address?: string;
  description: string;
  purpose?: string;
  ticker: string;
}

interface SettlementInfo {
  authorizedParticipantBPID: string;
  authorizedParticipantSettlementType: string;
  date: string;
  exchange: string;
  horizon: string;
  issuerBPID: string;
  issuerSettlementType: string;
  type: string;
}

interface OrderCreatedBy {
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  onBehalfOf?: {
    companyId: string;
    companyName: string;
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
  };
}

export type Deliverable = {
  adminFee?: number;
  amount?: number;
  currency?: string;
  executionFee?: number;
  executionFeeBps?: number;
  price?: number;
  ticker: string;
  totalFees?: number;
  totalInCurrency?: number;
  totalInDeliveryCurrency?: number;
  totalWithFees?: number;
  type?: OrderAllowedDeliveries;
  fxRate?: string;
};

export interface OrderTrade extends BaseEntry {
  action: TradeAction;
  additionalSlippage?: number;
  adminFee: number;
  baseCurrency: string;
  // For Order Trade With Fees
  executionFee: number;
  executionFeeBps: number;
  price?: number; // Price at that AP will pay
  priceFromTrading?: number;
  priceToPrefund?: number;
  quantity: number;
  quoteCurrency: string;
  scheduledAt: string; // UTC
  slippage?: number;
  status: TradeStatus;
  total: number;
  totalFees: number;
  totalWithFees: number;
  totalFromPrefund: number;
  totalInDeliveryCurrency: number;
}

export interface Order {
  _id: string;
  authorizedParticipantCode: number;
  authorizedParticipantLegalAgreementStartDate: string;
  authorizedParticipantWallets?: WalletInfo[];
  _actions?: Actions;
  bookedAt?: Date;
  createdAt?: Date;
  createdBy: OrderCreatedBy;
  dealDate?: string;
  deliveries?: {
    expected: Deliverable[];
    actual: Deliverable[];
    expectedTotalCash: number;
    expectedTotalCurrency: Currency;
  };
  deliveryType: OrderAllowedDeliveries;
  deliveryTypeOptions?: OrderAllowedDeliveries[];
  deliveryCurrency: string;
  externalId?: string;
  hasPortfolioComposition?: boolean;
  issuerWallets?: WalletInfo[];
  numberOfSecurities?: number;
  numberOfUnits: number;
  orderIdentifier?: string;
  documents?: {
    confirmationPdf?: {
      name: string;
      url: string;
    };
  };
  pricingType?: PricingType;
  pricingTypeOptions?: string[];
  product: OrderProductInfo;
  settledAt?: Date;
  settlement?: SettlementInfo;
  settlementTypeOptions?: string[];
  slippage: number;
  status: OrderStatus;
  trades?: OrderTrade[];
  tradesAdminFee?: number;
  tradesExecutionFee?: number;
  tradesExecutionFeeBps?: number;
  tradesTotalFees?: number;
  tradesTotalWithExecutionFees?: number;
  tradesTotalWithTotalFee?: number;
  transactionHash?: string;
  type: OrderType;
  tags?: string[];
  tradesDeliveryCurrencyFxRate?: number;
  tradesTotal?: number;
  // ADJUSTED FEES
  tradesAdjustedAdminFee?: number;
  tradesAdjustedExecutionFee?: number;
  tradesTotalSlippageFromTotalAndPriceProvider?: number;
  tradesTotalFromPriceProvider?: number;
  tradesTotalFeesInDeliveryCurrency?: number;
  tradesTotalWithTotalFeeInDeliveryCurrency?: number;
  tradesTotalFromPriceProviderInDeliveryCurrency?: number;
  // TOKENS
  settlementDate: string;
}

export enum TradeAction {
  SELL = 'SELL',
  BUY = 'BUY',
}

export enum TradeStatus {
  PENDING = 'PENDING',
  FILLED = 'FILLED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export type OrderFormProps = {
  deliveries?: Deliverable[];
  deliveryType: OrderAllowedDeliveries;
  deliveryCurrency?: string;
  numberOfUnits: number;
  productId: string;
  settlementType: string;
  type: OrderType;
  identifier?: string;
  submit?: boolean;
  pricingType?: string;
  companyId?: string;
  userId?: string;
  cashExecutionFeeBps?: number;
};

export type OrderFlow = 'create' | 'delegate' | 'confirm';
