import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
// hooks
import useToggle from 'hooks/useToggle';
import useEvents from 'components/Select/hooks/useEvents';

// types
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
  OptionTypeWithGroup,
} from 'components/Select/Select.types';
import { PopperPlacementType } from '@mui/material/Popper';

// helpers
import findSelected from 'components/Select/helpers/findSelected';
import transformOptions from './helpers/transformOptions';

// components
import {
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  OutlinedInput,
  Typography,
  Stack,
  FormLabel,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

// styles, assets
import {
  MuiSingleSelectGridContainer,
  MuiSingleSelectValueWrapper,
  StyledGrid,
  StyledGridItem,
} from 'components/Select/Select.styles';
import { ReactComponent as CheckIcon } from 'assets/check.svg';

export interface SelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  clearable?: boolean;
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  filterable?: boolean;
  helperText?: string;
  inline?: boolean;
  isInvalid?: boolean;
  label?: string;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  onChange?: OnChangeType<T>;
  onFilter?: (filter: string | undefined) => void;
  value?: T;
  mapOptions?: (options: OptionType<T>[]) => OptionType<T>[];
  resetOnChange?: string[];
  optionsPlacement?: PopperPlacementType;
  isPaginationSelect?: boolean;
  size?: 'small' | 'medium' | 'large';
  allCaps?: Boolean;
  capitalize?: Boolean;
  backgroundColor?: string;
}

function SelectComponent<T extends OptionValueType>({
  className,
  clearable,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  disabled,
  errorMessage,
  helperText,
  filterable,
  inline,
  isInvalid,
  label,
  onChange,
  onFilter,
  options,
  placeholder,
  value,
  optionsPlacement,
  isPaginationSelect,
  size = 'large',
  backgroundColor = 'transparent',
  ...props
}: SelectProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLButtonElement>(null);

  const clearFilter = () => {
    if (onFilter) {
      onFilter(undefined);
    }
  };
  const [isOptionsOpen, toggleOptions] = useToggle(false, { onClose: clearFilter });

  useEffect(() => {
    if (isInvalid && !filterable) {
      innerRef?.current?.setCustomValidity('invalid');
    } else {
      innerRef?.current?.setCustomValidity('');
    }
  }, [isInvalid, filterable]);

  useEvents(isOptionsOpen, containerRef, toggleOptions, optionsRef);

  return filterable ? (
    <MuiAutocomplete
      size={size}
      helperText={helperText}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      label={label}
      errorMessage={errorMessage}
      disabled={disabled}
      {...props}
    />
  ) : (
    <MuiSelect
      backgroundColor={backgroundColor}
      helperText={helperText}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      label={label}
      errorMessage={errorMessage}
      disabled={disabled}
      isPaginationSelect={isPaginationSelect}
      size={size}
      {...props}
    />
  );
}

export default SelectComponent;

export function MuiAutocomplete<T extends OptionValueType>({
  disabled,
  errorMessage,
  helperText,
  label,
  onChange,
  options,
  value,
  className,
  placeholder,
  size = 'small',
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
}: SelectProps<T>) {
  const selectedValue = findSelected(value, options) as OptionType<T>;

  const inputSizeProps = {
    small: { size: 'small', height: 32 },
    medium: { size: 'medium', height: 40 },
    large: { size: 'medium', height: 48 },
  }[size];

  return (
    <Stack spacing="4px">
      {label && <FormLabel>{label}</FormLabel>}
      <Autocomplete<OptionTypeWithGroup<T>>
        data-qa-id={dataQaId}
        className={className}
        options={transformOptions(options)}
        groupBy={(option) => option.group ?? ''}
        getOptionLabel={(option) => option.label}
        value={selectedValue ?? null}
        onChange={(_, newValue) => {
          onChange && onChange(newValue?.value ?? undefined);
        }}
        style={{ padding: 0 }}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '0px',
          },
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <MuiSingleSelectGridContainer container data-qa-options-id={dataQaOptionsId}>
              {option.icon && <StyledGridItem item>{option.icon}</StyledGridItem>}
              <StyledGrid>{option.label}</StyledGrid>
              {selectedValue?.label === option.label && (
                <StyledGridItem item>
                  <CheckIcon />
                </StyledGridItem>
              )}
            </MuiSingleSelectGridContainer>
          </li>
        )}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            size={inputSizeProps.size as 'small' | 'medium'}
            placeholder={placeholder}
            helperText={errorMessage || helperText}
            error={!!errorMessage}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0',
              },
              '& .MuiInputAdornment-root': {
                marginRight: size === 'small' ? '0px' : '4px',
              },
            }}
            InputProps={{
              style: { height: `${inputSizeProps.height}px`, padding: '0 8px 0 8px' },
              ...params.InputProps,
              startAdornment: (
                <>
                  {selectedValue?.icon && (
                    <InputAdornment position="start">{selectedValue.icon}</InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}

export function MuiSelect<T extends OptionValueType>({
  disabled,
  errorMessage,
  helperText,
  label,
  onChange,
  options,
  value,
  className,
  placeholder,
  isPaginationSelect,
  size = 'small',
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  backgroundColor = 'transparent',
}: SelectProps<T>) {
  const selectedValue = findSelected(value, options) as OptionType<T>;
  const items = transformOptions(options);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    onChange && onChange(event.target.value as T);
  };

  const inputSizeProps = {
    small: { size: 'small', height: 32 },
    medium: { size: 'medium', height: 40 },
    large: { size: 'medium', height: 48 },
  }[size];
  return (
    <FormControl fullWidth variant="outlined" disabled={disabled} error={!!errorMessage}>
      <Stack spacing="4px">
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          sx={{
            background: backgroundColor,
            '& .MuiOutlinedInput-notchedOutline': isPaginationSelect ? { border: 'none' } : {},
          }}
          data-qa-id={dataQaId}
          displayEmpty
          className={className}
          value={selectedValue ?? ''}
          onChange={handleChange}
          input={
            <OutlinedInput
              notched={false}
              style={{ height: `${inputSizeProps.height}px` }}
              size={size}
            />
          }
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return (
                <Typography color="grey.500" variant="labelMedium">
                  {placeholder ?? label}
                </Typography>
              );
            }
            return (
              <MuiSingleSelectValueWrapper spacing={1}>
                {selected.icon && selected.icon}
                {selected.label}
              </MuiSingleSelectValueWrapper>
            );
          }}
        >
          {items.map((option, index) => (
            <MenuItem key={index} value={String(option.value)} data-qa-options-id={dataQaOptionsId}>
              <MuiSingleSelectGridContainer container>
                {option.icon && <StyledGridItem item>{option.icon}</StyledGridItem>}
                <StyledGrid>{option.label}</StyledGrid>
                {selectedValue?.label === option.label && (
                  <StyledGridItem item>
                    <CheckIcon />
                  </StyledGridItem>
                )}
              </MuiSingleSelectGridContainer>
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Stack>
    </FormControl>
  );
}
